html:has(#head-to-head){
    height: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    background-image: url('../../../assets/images/nfl-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body:has(#head-to-head){
    height: 100%;
    background: none;
}

#head-to-head .header-title{
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 2em;
    text-shadow: 2px 2px 5px black;
}

#head-to-head .four-item .item-pick{
    width: calc(100%/4);
}

#head-to-head .four-item .item-pick .label{
    font-size: 1em;
}

#head-to-head .two-item .item-pick{
    width: calc(100%/2);
}

#head-to-head .item-pick .label{
    color: white;
    text-transform: uppercase;
    font-size: 1.5em;
    background-color: gray;
    font-weight: bold;
}

#head-to-head .item-pick .img{
    aspect-ratio : 1 / 1;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 8%;
}

#head-to-head .item-pick .img img{
    width: 100%;
    aspect-ratio : 1 / 1;
}

#head-to-head .four-item .item-pick .stats .container .title{
    font-size: 0.6em;
}

#head-to-head .item-pick .stats .title{
    font-stretch: condensed;
    font-size: 0.8em;
}

#head-to-head .item-pick .stats .container{
    border-right: 1px gray solid;
}

#head-to-head .item-pick .stats .container:first-child{
    border-left: 1px gray solid;
}

#head-to-head .four-item .item-pick .stats .container .value{
    font-size: 1em;
}

#head-to-head .item-pick .stats .value{
    font-weight: bold;
    font-size: 1.2em;
}

#head-to-head .ticket-container{
    color: white;
}

#head-to-head .ticket-container ul{
    list-style-type: none;
}

#head-to-head .ticket-container li{
    border-bottom: 1px dotted white;
    line-height: 2.5em;
}

#head-to-head .ticket-container li:last-child{
    border-bottom: 0px;
}

#head-to-head .selected{
    background-color: rgb(230,195,0);
}

#head-to-head .selected .label{
    background-color: rgb(230,195,0);
    border-bottom: solid gray 1px;
}

#head-to-head .selected .img img{
    -webkit-filter: drop-shadow(0px 0px 30px rgba(230,195,0, 0.8));
    filter: drop-shadow(0px 0px 30px rgba(230,195,0, 0.8))
}

#head-to-head .selected .title, #head-to-head .selected .value{
    color: white
}

#head-to-head .left{
    border-radius: 0 0 0 15px;
}

#head-to-head .right{
    border-radius: 0 0 15px 0;
}