html:has(#pick-the-props){
    height: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    background-image: url('../../../assets/images/nfl-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body:has(#pick-the-props){
    height: 100%;
    background: none;
}

#pick-the-props .header-title{
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 2em;
    text-shadow: 2px 2px 5px black;
}

#pick-the-props .item-pick{
    border-bottom: 1px solid white;
}

#pick-the-props .item-pick:last-child{
    border-bottom: 0px solid white;
}

#pick-the-props .img img{
    width: 90%;
    height: auto;
}

#pick-the-props .description{
    font-size: 1.2em;
}

#pick-the-props .label{
    color: white;
    background-color: gray;
    font-weight: bold;
}

#pick-the-props .label .title{
    text-transform:uppercase;
    font-size: 1.4em;
}

#pick-the-props .label .text{
    text-transform:none;
    font-size: 1.1em;
}

#pick-the-props .ticket-container{
    color: white;
}

#pick-the-props .ticket-container ul{
    list-style-type: none;
}

#pick-the-props .ticket-container li{
    border-bottom: 1px dotted white;
    line-height: 2.5em;
}

#pick-the-props .ticket-container li:last-child{
    border-bottom: 0px;
}

#pick-the-props .selected{
    background-color: rgb(230,195,0);
}

#pick-the-props .selected .label{
    background-color: rgb(230,195,0);
    border-bottom: solid gray 1px;
}

#pick-the-props .img{
    width: 150px;
}

@media screen and (max-width: 768px) {
    #pick-the-props .img{
        width: 120px;
    }
}


#pick-the-props .selected_2 .img{
    -webkit-filter: drop-shadow(0px 0px 30px rgba(230,195,0, 0.8));
    filter: drop-shadow(0px 0px 30px rgba(230,195,0, 0.8));
}

#pick-the-props .selected .title, #head-to-head .selected .value{
    color: white
}