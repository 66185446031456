html:has(#home){
    min-height: 100%;
    background-image: url('../../assets/images/Banner-2400-x-1350-new.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#home .carousel-container .my-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    line-height: 1.5;
    font-size: 1.5em;
    color: #f5f5f5;
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 768px) {
    #home .carousel-container .my-slide{
        padding: 10px;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
    #home .carousel-container .my-slide{
        padding: 30px;
    }
}

.welcome-modal .carousel-container-popup .slide{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#home .carousel .slide img {
    max-height: 400px;  /* change this to whatever you want */
    max-width: 400px;
}

@keyframes card-swipe {
    from {left: -50px;}
    to {left: 50px;}
}

.swipe-card-container{
    height: 70px;
}

.card-swipe {
    left: -50px;
    position: relative;
    animation-name: card-swipe;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background-color: white;
    padding: 5px 5px 0px 5px;
    z-index: 2;
}

.card-swipe-bar-front{
    margin: auto;
    width: 250px;
    height: 25px;
    background-color: black;
    position: relative;
    top: -20px;
    z-index: 3;
    border-radius: 15px 15px 0px 0px;

}

.card-swipe-bar-back{
    margin: auto;
    width: 200px;
    height: 20px;
    background-color: black;
    position: relative;
    top: -65px;
    z-index: 0;
    border-radius: 15px 15px 0px 0px;
}

.card-swipe-bar-back-2{
    margin: auto;
    width: 250px;
    height: 20px;
    background-color: white;
    position: relative;
    top: -70px;
    z-index: 1;
}

.home-quadrado {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    width: 100%;
    animation: crescendo 1.2s alternate infinite ease-in;
}

@keyframes crescendo {
    0%   {width: 100%}
    60%   {width: 100%}
    100% {width: 120%}
}

.home-logo{
    width: 70%;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-banner {
    width: 50%;
    position: fixed;
    bottom: 48%;
    left: 50%;
    transform: translate(-50%, 6%);
}

.home-calendar {
    width: 50%;
    position: fixed;
    bottom: 60%;
    left: 22%;
    transform: translate(-50%, 0%);
    animation: bounce2 1.2s alternate infinite ease-in;
}

@keyframes bounce2 {
    0%   {transform: scale(1);}
    60% {transform: scale(1);}
    100% {transform: scale(1.2);}
}

.shadowfilter {
    -webkit-filter: drop-shadow(5px 5px 7px rgba(250,250,210, 0.5));
    filter: drop-shadow(5px 5px 7px rgba(250,250,210, 0.5));
}

@keyframes bounce {
    0%   {bottom: 2%}
    100% {bottom: 4%}
}

.home-gradient-border {
    flex: 1;
    --border-width: 10px;

    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    font-family: Lato, sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: white;

    &::after {
        position: absolute;
        content: "";
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        z-index: -1;
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        background: linear-gradient(
        60deg,
        #e47200,
        #ece75f,
        #e6cc00,
        #e6b400,
        #e5de00,
        #e69b00,
        #e8e337
        );
        background-size: 300% 300%;
        background-position: 0 50%;
        border-radius: calc(2 * var(--border-width));
        animation: moveGradient 2s alternate infinite;
    }
}

@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}

.home-frame {
    overflow: hidden;
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    position: relative;
}

.home-video {
    object-fit: cover;
    width:  100%; 
    height:  100%; 
    margin: 0;
    padding: 0;
    position: absolute;
    top:0;
}

.home-background {
  width:  100%; 
  height:  100%; 
  margin: 0;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-video-2 {
    object-fit: cover;
    width:  100%; 
    height:  100vh; 
    margin: 0;
    padding: 0;
}

.home-insert-card {
    position: fixed;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, 1%);
    /*animation: bounce .6s alternate infinite ease-out;*/
}

.home-arrow{
    position: fixed; 
    bottom: 2%; 
    right: 19%; 
    animation: bounce .5s alternate infinite ease-out;
}

.calendar {
    position: fixed;
    left: 180px;
    top: 220px;
    width: 240px;
    cursor: default;
    user-select: none;
    color:black;
    transform: rotate(10deg);
    font-size: 30px;
    line-height: 30px;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -28px;
      left: 40px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #ddd;
      z-index: 3;
    }
    
    &::after {
      left: initial;
      right: 40px;
    }
  }
  
  .pages {
    position: relative;
    text-align: center;
    background: #eee;
    box-shadow: 0 10px 0 0px #a5a4a4;
    
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 45px;
      background: indianred;
      bottom: 100%;
      left: 0;
      z-index: 2;
    }
  }
  
  .page {
    position: relative;
    padding: 20px 30px 15px;
    background: #eee;
    
    &::before {
      content: '';
      position: absolute;
      bottom: 99%;
      left: 0;
      display: block;
      background: 
        linear-gradient(-45deg, #eee 10px, #eee 10px, #eee 10px,  transparent 0), 
        linear-gradient(45deg, #eee 10px, transparent 0);
      background-position: left top;
      background-repeat: repeat-x;
      background-size: 10px 18px;
      height: 10px;
      width: 100%;
    }
  }
  
  .month,
  .day-name {
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .day {
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 15px;
  }
  
  .year {
    font-size: 12px;
  }
  
  .tear {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: top left;
    box-shadow: 0 0 10px -1px rgba(0,0,0,0.5);
    pointer-events: none;
    animation: tear-animation .8s linear forwards;
  }
  
  @keyframes tear-animation {
    0% {
      transform: rotate(0deg);
      top: 0;
      opacity: 1;
    }
    20% {
      transform: rotate(9deg);
      top: 0;
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      top: 200px;
      opacity: 0;
    }
  }
  