html:has(#bracket){
    height: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    background-image: url('../../../assets/images/nfl-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

body:has(#bracket){
    height: 100%;
    background: none;
}

#bracket .header-title{
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 2em;
    text-shadow: 2px 2px 5px black;
}

#bracket .four-item .item-pick{
    width: calc(100%/4);
}

#bracket .four-item .item-pick .label{
    font-size: 1em;
}

#bracket .two-item .item-pick{
    width: calc(100%/2);
}

#bracket .item-pick .label{
    color: white;
    text-transform: uppercase;
    font-size: 1.5em;
    background-color: gray;
    font-weight: bold;
}

#bracket .item-pick .img{
    aspect-ratio : 1 / 1;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 8%;
}

#bracket .item-pick .img img{
    width: 100%;
    aspect-ratio : 1 / 1;
}

#bracket .four-item .item-pick .stats .container .title{
    font-size: 0.6em;
}

#bracket .item-pick .stats .title{
    font-stretch: condensed;
    font-size: 0.8em;
}

#bracket .item-pick .stats .container:first-child{
    border-left: 1px gray solid;
}

#bracket .item-pick .stats .container{
    border-right: 1px gray solid;
}

#bracket .four-item .item-pick .stats .container .value{
    font-size: 1em;
}

#bracket .item-pick .stats .value{
    font-weight: bold;
    font-size: 1.2em;
}

.appear{
    animation-name: appear-animation;
    animation-duration: 1s;
    opacity: 1;
    left: 0px;
    position: relative;
}

.re-appear{
    animation-name: re-appear-animation;
    animation-duration: 1s;
    opacity: 1;
    left: 0px;
    position: relative;
}

@keyframes appear-animation {
    0%   {opacity: 1; left: 0px}
    50%  {opacity: 0; left: 150px}
    51%  {opacity: 0; left: -150px}
    100% {opacity: 1; left: 0px}
}

@keyframes re-appear-animation {
    0%   {opacity: 1; left: 0px}
    50%  {opacity: 0; left: -150px}
    51%  {opacity: 0; left: 150px}
    100% {opacity: 1; left: 0px}
}

#bracket .ticket-container{
    color: white;
}

#bracket .ticket-container ul{
    list-style-type: none;
}

#bracket .ticket-container li{
    border-bottom: 1px dotted white;
    line-height: 2.5em;
}

#bracket .ticket-container li:last-child{
    border-bottom: 0px;
}

#bracket .selected{
    background-color: rgb(230,195,0);
}

#bracket .selected .label{
    background-color: rgb(230,195,0);
    border-bottom: solid gray 1px;
}

#bracket .selected .img img{
    -webkit-filter: drop-shadow(0px 0px 30px rgba(230,195,0, 0.8));
    filter: drop-shadow(0px 0px 30px rgba(230,195,0, 0.8))
}

#bracket .selected .title, #bracket .selected .value{
    color: white
}

#bracket .left{
    border-radius: 0 0 0 15px;
}

#bracket .right{
    border-radius: 0 0 15px 0;
}