body{
  background-color: transparent;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.custom-scroll{
  overflow: hidden auto;

}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.master-modal .modal-content{
  background-color: rgba(0, 0, 0, 0)!important;
  border: solid 0px rgba(0, 0, 0, 0)!important;
}

.disclaimer{
  font-size: 0.8rem;
}

.numpad-modal .modal-content{
  background-color: #ececec!important;
}

.numpad-modal .modal-body{
  padding: 5px;
}

.numpad-modal .btn{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 5px;
}

.numpad-modal .btn-danger{
  background-color: #dc3545!important;
}

.numpad-modal .btn-success{
  background: #198754!important;
}

.virtual-keyboard{
  position: fixed;
  bottom: -100%;
  animation-name: disappear-keyboard;
  animation-duration: 0.2s;
  z-index: 10001;
}

.virtual-keyboard-appear{
  position: fixed;
  animation-name: appear-keyboard;
  animation-duration: 0.2s;
  bottom: 0;
  z-index: 10001;
}

@keyframes appear-keyboard {
  from {bottom: -100%;}
  to {bottom: 0;}
}

@keyframes disappear-keyboard {
  from {bottom: 0%;}
  to {bottom: -100%;}
}

.invincible-wall{
  background-color: rgba(0,0,0,0);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  left: 0;
  top: 0;
}

.border-right-whitesmoke{
  border-right: 1px solid whitesmoke;
}


.border-left-whitesmoke{
  border-left: 1px solid whitesmoke;
}

.left .stats{
  border-right: 1px gray solid;
}

.right .stats{
  border-left: 1px gray solid;
}

.modal-body-text{
  padding-left: 20px;
}

.modal-body-text li{
  margin-bottom: 20px;
}

.modal-body-text li:last-child{
  margin-bottom: 0px;
}

@keyframes glow {
  50% {box-shadow: 0px 0px 10px #198754, 0px 0px 25px rgb(230,195,0)}
}

.btn-custom-submit{
  -webkit-animation: glow 1.5s ease-in-out infinite;
  -moz-animation: glow 1.5s ease-in-out infinite;
  animation: glow 1.5s ease-in-out infinite;
}

.receipt{
  display: none;
}

@media print {
  @page {
    size: 80mm 200mm;
    margin: 0!important;
    padding: 0!important;
  }
  html{ box-shadow: none!important; overflow: visible!important;}
  .modal, .main-container, .modal-backdrop{display:none!important;}
  .receipt{ display: block!important; margin-top: 20px!important}
  .receipt li{
    padding: 0!important;
  }

  .receipt div.event:first-child{
    margin-top: 0px;
  }

  .receipt div.event{
    margin-top: 8px;
  }
}

.fs-7{
  font-size: 0.7em;
}

.error-modal .text{
  padding: 0;
  list-style: none;
}

.error-modal .text li{
  margin-bottom: 10px;
}

.error-modal .text li:nth-last-child(1){
  margin-bottom: 0px;
}

.scroll-container, .user-picks-scroll-container{
  overflow: hidden scroll;
  /*height: 500px;*/
  /*margin-right: -15px;*/
}

.scroll-container::-webkit-scrollbar, .user-picks-scroll-container::-webkit-scrollbar, .custom-scroll::-webkit-scrollbar {
  width: 0em;
}

/* Track */
.scroll-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 0 15px 15px 0;
}

/* Handle */
.scroll-container::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 0 15px 15px 0;
}

/* Handle on hover */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: black; 
}

.mobile-ticket-parent-container{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
}

.mobile-padding{
  display: none;
  height: 60px;
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 768px) {
  .mobile-ticket-parent-container, .mobile-padding{
    display: block;
  }

  .choice-parent-container{
    flex: 1 0!important;
  }
  
  .ticket-parent-container{
    display: none;
  }
}

.carousel.carousel-slider .control-arrow{
  background-color: rgba(0, 0, 0, 0.7)!important;
  border-radius: 10px;
}

.main-carousel .carousel.carousel-slider .control-arrow{
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center
}

.carousel .carousel-status{
  display: none!important;
}

.hg-theme-default .hg-button{
  font-size: 1.4rem;
}